<template>
  <div :class="['nav', { 'nav-color': navColor }]">
    <!-- web端导航 -->
    <div class="web-nav hidden-sm-and-down" style="position: relative">
      <img
        class="logo-icon cursor-p"
        src="../assets/img/hongtulogo.png"
        @click="jump('/')"
      />

      <div class="nav-menu">
        <el-menu
          class="el-menu-demo web-el-menu"
          mode="horizontal"
          :default-active="$route.path"
          router
        >
          <el-submenu index="1">
            <template slot="title"><a href="javascript:;">产品</a></template>
            <el-menu-item class="item-first">
              <div class="el-menu-pro">
                <div
                  class="pro-item"
                  v-for="item in webProductList"
                  :key="item.id"
                >
                  <div class="pro-item-tit">{{ item.title }}</div>
                  <el-divider class="divider"></el-divider>
                  <div
                    class="pro-item-li"
                    v-for="(menuItem, i) in item.menu"
                    :key="i"
                  >
                    <el-menu-item :index="menuItem.path">
                      <a v-if="menuItem.track" v-track="menuItem.track">{{
                        menuItem.name
                      }}</a>
                      <span v-else>{{ menuItem.name }}</span>
                    </el-menu-item>

                    <img
                      src="@/assets/img/new1.png"
                      class="iconNew"
                      v-show="menuItem.isNew"
                    />

                    <img
                      src="@/assets/img/hot1.png"
                      class="iconHot"
                      v-show="menuItem.isHot"
                    />

                    <p>{{ menuItem.desc }}</p>
                  </div>
                </div>
              </div>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title"
              ><a href="javascript:;">解决方案</a></template
            >
            <el-menu-item>
              <div class="el-menu-pro mentu-pro">
                <div
                  class="pro-item"
                  v-for="solut in webSolutionList"
                  :key="solut.id"
                >
                  <div class="pro-item-tit">
                    {{ solut.title }}
                  </div>
                  <el-divider class="divider"></el-divider>
                  <div
                    class="pro-item-li"
                    v-for="(solutChild, i) in solut.menu"
                    :key="i"
                  >
                    <el-menu-item :index="solutChild.path">
                      <span> {{ solutChild.name }}</span>
                    </el-menu-item>
                    <p>{{ solutChild.desc }}</p>
                  </div>
                </div>
              </div>
            </el-menu-item>
          </el-submenu>

          <el-menu-item class="el-submenu jj">
            <a :href="$store.state.docUrl" target="_blank">文档中心</a>
          </el-menu-item>

          <el-menu-item class="el-submenu jj">
            <a href="/community">社区</a>
          </el-menu-item>

          <el-menu-item index="/contact" class="el-submenu jj"
            ><a
              href="/contact"
              v-track="{
                act: 'click',
                node: {
                  eventName: '联系我们',
                  eventParentType: 2,
                  eventChildType: 26,
                  clickType: 1,
                },
              }"
              >联系我们</a
            ></el-menu-item
          >
        </el-menu>
      </div>
      <div>
        <a
          :href="`${link}?eventParentType=1&eventChildType=11&clickType=2&eventName='登录成功次数'`"
          target="_blank"
          class="login-btn"
          v-track="{
            act: 'click',
            node: {
              eventName: '登录次数',
              eventParentType: 1,
              eventChildType: 11,
              clickType: 1,
            },
          }"
          @click="login()"
          >登录</a
        >

        <a
          @click="register()"
          :href="`${link}?eventParentType=1&eventChildType=12&clickType=3&eventName='注册成功次数'`"
          class="register-btn"
          v-track="{
            act: 'click',
            node: {
              eventName: '注册次数',
              eventParentType: 1,
              eventChildType: 12,
              clickType: 1,
            },
          }"
          >免费注册</a
        >
      </div>
    </div>
    <!-- 移动端导航 -->
    <div class="app-nav hidden-md-and-up">
      <div class="flex">
        <img
          class="logo-icon cursor-p"
          src="../assets/img/hongtulogo.png"
          @click="jump('/')"
        />

        <div>
          <a
            :href="`${link}?eventParentType=1&eventChildType=11&clickType=2&eventName='登录成功次数'`"
            class="login-btn"
            v-track="{
              act: 'click',
              node: {
                eventName: '登录次数',
                eventParentType: 1,
                eventChildType: 11,
                clickType: 1,
              },
            }"
            @click="login()"
            >登录</a
          >

          <a
            @click="register()"
            :href="`${link}?eventParentType=1&eventChildType=12&clickType=3&eventName='注册成功次数'`"
            class="register-btn"
            v-track="{
              act: 'click',
              node: {
                eventName: '注册次数',
                eventParentType: 1,
                eventChildType: 12,
                clickType: 1,
              },
            }"
            >免费注册</a
          >

          <span class="app-nav-menu">
            <svg-icon
              icon-class="liebiao"
              class="icon-liebiao"
              @click="isCollapse = !isCollapse"
            />
          </span>
        </div>
      </div>
      <div :class="['app-menu', { isCollapse: isCollapse }]">
        <el-menu
          class="el-menu-vertical-demo"
          :default-active="$route.path"
          router
        >
          <el-submenu index="1">
            <template slot="title"><span>产品</span></template>
            <el-menu-item-group
              v-for="item in productList"
              :key="item.id"
              :index="item.id"
            >
              <template slot="title"
                ><span class="title-line mr10"></span>{{ item.title }}</template
              >
              <el-menu-item
                :index="menu.path"
                v-for="(menu, i) in item.menu"
                :key="i"
                @click="isCollapse = !isCollapse"
                >{{ menu.name }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title"
              ><span class="title-line mr10">解决方案</span></template
            >
            <el-menu-item-group
              v-for="item in solutionList"
              :key="item.id"
              :index="item.id"
            >
              <template slot="title"
                ><span class="title-line mr10"></span>{{ item.title }}</template
              >
              <el-menu-item
                :index="menu.path"
                v-for="(menu, i) in item.menu"
                :key="i"
                @click="isCollapse = !isCollapse"
                >{{ menu.name }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-menu-item class="el-submenu jj">
            <a :href="$store.state.docUrl" target="_blank">文档中心</a>
          </el-menu-item>

          <el-menu-item class="el-submenu jj">
            <div><a href="/community">社区</a></div>
          </el-menu-item>

          <el-menu-item index="/contact" class="el-submenu jj"
            ><a
              href="/contact"
              v-track="{
                act: 'click',
                node: {
                  eventName: '联系我们',
                  eventParentType: 2,
                  eventChildType: 26,
                  clickType: 1,
                },
              }"
              >联系我们</a
            ></el-menu-item
          >
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  name: "Nav",
  data() {
    return {
      link: process.env.VUE_APP_USERCONSOLE,
      show: false,
      navColor: false,
      isCollapse: false,
      // web端产品
      webProductList: [
        {
          id: 1,
          title: '凤羽引擎',
          menu: [
            {
              path: '/human/beautySDK', name: '人像美颜特效',
              desc: '快速实现美颜、美型、滤镜等功能',
              track: {
                act: 'click',
                node: {
                  eventName: '人像美颜',
                  eventParentType: 2,
                  eventChildType: 21,
                  clickType: 1,
                },
              }
            },
            { path: '/human/ARMakeup', name: 'AR虚拟美妆', desc: '虚拟试妆，百变妆容自由定义', isHot: true },

            { path: '/human/ARSticker', name: 'AR贴纸道具', desc: '趣味玩法，3D道具助力平台营收增长', isNew: true },
            { path: '/human/portrait', name: '人像抠图', desc: '绿幕、AI智能替换背景，电商直播秒换物料', isHot: true },
            { path: '/human/3DAvatar', name: '3D Avatar', desc: '多种3D特效和表情驱动', },
            { path: '/human/behavior', name: '肢体识别', desc: '动作肢体的检测与识别', },
          ]
        },
        {
          id: 2,
          title: '烛照引擎',
          menu: [
            {
              path: '/smart/photoReview', name: '图片审核',
              desc: '精准识别违规图片，助力平台合规化',
              track: {
                act: 'click',
                node: {
                  eventName: '内容审核',
                  eventParentType: 2,
                  eventChildType: 22,
                  clickType: 1,
                },
              }
            },
            {
              path: '/smart/videoReview', name: '视频审核',
              desc: '实时视频检测，用户无感知',
              track: {
                act: 'click',
                node: {
                  eventName: '内容审核',
                  eventParentType: 2,
                  eventChildType: 22,
                  clickType: 1,
                },
              }
            },
            {
              path: '/smart/audioReview', name: '音频审核',
              desc: '精准高效识别风险音频内容',
              track: {
                act: 'click',
                node: {
                  eventName: '内容审核',
                  eventParentType: 2,
                  eventChildType: 22,
                  clickType: 1,
                },
              }
            },
            {
              path: '/smart/textReview', name: '文字审核',
              desc: '有效识别各类场景中的风险文本内容',
              track: {
                act: 'click',
                node: {
                  eventName: '内容审核',
                  eventParentType: 2,
                  eventChildType: 22,
                  clickType: 1,
                },
              }
            },
            {
              path: '/smart/authenticat', name: '人脸实名认证',
              desc: '身份证OCR、人脸比对、真人认证',
              track: {
                act: 'click',
                node: {
                  eventName: '人脸识别认证',
                  eventParentType: 2,
                  eventChildType: 23,
                  clickType: 1,
                },
              }
            },
          ]
        },
        {
          id: 3,
          title: '智能应用',
          menu: [
            {
              path: '/smart/smartCS', name: 'AIGC智能在线客服系统',
              desc: '人工+智能AI客服机器人系统',
              track: {
                act: 'click',
                node: {
                  eventName: '在线客服',
                  eventParentType: 2,
                  eventChildType: 24,
                  clickType: 1,
                },
              }
            },]
        },
        {
          id: 4,
          title: '图像技术',
          menu: [
            { path: '/image-technology/effects', name: '图像特效', desc: '包含多种图像风格特效', },
            { path: '/image-technology/enhance', name: '图像增强', desc: '多种图片增强特效提高图像质量', },
          ]
        }
      ],
      // web端解决方案
      webSolutionList: [
        {
          id: 1,
          title: '内容增强',
          menu: [
            { path: '/industry/human', name: '人像人体视频特效解决方案', desc: '多插件化组件，保障平台运营数据增长', },
            { path: '/industry/liveTerminal', name: '直播终端视觉特效解决方案', desc: '从研发到运营，全面降本增效' },
            // { path: '/scene/metagalaxies', name: '虚拟人视觉驱动解决方案', desc: '为meta虚拟人场景提供AI驱动解决方案' },
            { path: '/scene/photoBooth', name: 'AI美拍机特效解决方案', desc: '提供从人像美化到AIGC特效全链路解决方案' },

          ]
        },
        {
          id: 2,
          title: '内容风控',
          menu: [
            { path: '/scene/socialize', name: '社交娱乐内容风控解决方案', desc: '打造真正深度适配社交行业的综合风控方案' },
            { path: '/scene/educate', name: '智慧教务合规化风控解决方案', desc: '提供教育行业的定制化风控方案' },
            { path: '/scene/content', name: 'AIGC内容风控解决方案', desc: '提供识别实时生成的内容信息风控方案' },
          ]
        },
        {
          id: 3,
          title: '内容服务',
          menu: [{ path: '/scene/smartCS', name: 'AIGC智慧在线客服解决方案', desc: '生成式AI赋能在线客服解决方案' },]
        },
      ],
      // 移动端产品
      productList: [{
        id: 1, title: '凤羽引擎',
        menu: [{ path: '/human/beautySDK', name: '人像美颜特效' },
        { path: '/human/ARSticker', name: 'AR贴纸道具' },
        { path: '/human/portrait', name: '人像抠图' },
        { path: '/human/3DAvatar', name: '3D Avatar' },
        { path: '/human/behavior', name: '肢体识别' }]
      },
      {
        id: 2, title: '烛照引擎',
        menu: [{ path: '/smart/photoReview', name: '图片审核' },
        { path: '/smart/videoReview', name: '视频审核' },
        { path: '/smart/audioReview', name: '音频审核' },
        { path: '/smart/textReview', name: '文字审核' },
        { path: '/smart/authenticat', name: '人脸实名认证' },
        ]
      },
      {
        id: 3, title: '智能应用',
        menu: [{ path: '/smart/smartCS', name: 'AIGC智能在线客服系统' },
        ]
      },
      {
        id: 4, title: '图像技术',
        menu: [{ path: '/image-technology/effects', name: '图像特效' },
        { path: '/image-technology/enhance', name: '图像增强' },
        ]
      },
      ],
      // 移动端解决方案
      solutionList: [{
        id: 1, title: '内容增强',
        menu: [{ path: '/industry/human', name: '人像人体视频特效解决方案' },
        { path: '/industry/liveTerminal', name: '直播终端视觉特效解决方案' },
        { path: '/scene/metagalaxies', name: '虚拟人视觉驱动解决方案' },
        ]
      },
      {
        id: 2, title: '内容风控',
        menu: [{ path: '/scene/socialize', name: '社交娱乐内容风控解决方案' },
        { path: '/scene/educate', name: '智慧教务合规化风控解决方案' },
        { path: '/scene/content', name: 'AIGC内容风控解决方案' },
        ]
      },
      {
        id: 3, title: '内容服务',
        menu: [{ path: '/scene/smartCS', name: 'AIGC智慧在线客服解决方案' },]
      },
      ]
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", () => {
      this.isCollapse = false;
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", () => {
      this.isCollapse = false;
    });
  },
  methods: {
    login() {
      let node = {
        eventName: "登录次数",
        eventParentType: 1,
        eventChildType: 11,
        clickType: 2,
      };
      Cookies.set("eventName", node.eventName);
      Cookies.set("eventParentType", node.eventParentType);
      Cookies.set("eventChildType", node.eventChildType);
      Cookies.set("clickType", node.clickType);
    },
    jump(path) {
      this.$router.push(path);
    },
    handleScroll(event) {
      const Y = document.documentElement.scrollTop || document.body.scrollTop;
      this.navColor = Y >= 72;
    },

  },
};
</script>
<style lang='scss'>
@media screen and (max-width: 1440px) {
  .header {
    width: 1440px;
  }

  .nav-menu {
    width: 1440px;
  }
}

@media screen and (max-width: 900px) {
  .el-menu--horizontal {
    left: 0 !important;
  }
}

.el-menu--horizontal .el-menu .el-menu-item {
  background-color: transparent !important;
  padding: 0 !important;
}

$myBlue: #249cff;
$myGrey: #646464;

.web-el-menu.el-menu--horizontal > .el-submenu .el-submenu__title {
  font-size: 16px !important;
  font-family: "PingFang SC", "microsoft yahei", tahoma, arial,
    "Hiragino Sans GB", sans-serif !important;
  font-weight: 400;
  color: #2d3144 !important;
  padding: 0;
  margin-right: 1.5625rem;
  height: 4.5rem !important;
  line-height: 4.5rem !important;
  box-sizing: border-box;
}

.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow:hover {
  color: #249cff !important;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title:hover {
  background: $myBlue;
}

.web-el-menu {
  &.el-menu {
    display: inline-block;
    vertical-align: top;
    background-color: transparent !important;

    &.el-menu--horizontal {
      border-bottom: none;

      &:focus {
        outline: 0 !important;
      }
    }
  }

  &.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    color: $myBlue !important;
  }

  .jj {
    font-size: 16px !important;
    font-family: "PingFang SC", "microsoft yahei", tahoma, arial,
      "Hiragino Sans GB", sans-serif !important;
    font-weight: 400;
    color: #2d3144 !important;
    width: 40px !important;
  }

  &.el-menu--horizontal > .mentu-pro {
    width: 200px !important;
  }

  &.el-menu--horizontal > .el-menu-item {
    font-weight: 400;
    padding: 0;
    margin-right: 25px;
    color: $myGrey;
    height: 80px !important;
    line-height: 80px !important;
    width: 844px;
    max-width: 1248px !important;
    box-sizing: border-box;

    &:last-of-type {
      margin-right: 0;
    }

    &.is-active {
      color: $myGrey;
      border-bottom: none;
    }

    &:focus,
    &:hover {
      background-color: transparent;
    }

    &:not(.is-disabled):focus,
    &:not(.is-disabled):hover {
      background-color: transparent !important;
      border-bottom: none;
    }
  }

  &.el-menu--horizontal > .el-submenu {
    &.is-active {
      .el-submenu__title {
        color: $myGrey;
        border-bottom: none;
      }
    }

    &:hover {
      .el-submenu__title {
        border-bottom: none;
      }
    }

    .el-submenu__title {
      font-size: 14px;
      font-family: "SourceHanSansCN-Regular";
      font-weight: 400;
      padding: 0;
      margin-right: 25px;
      color: $myGrey;
      height: 80px !important;
      line-height: 80px !important;
      box-sizing: border-box;

      &:hover {
        background-color: transparent !important;
        border-bottom: none;
      }

      .el-submenu__icon-arrow {
        color: $myGrey;
        margin-left: 4px;

        &.el-icon-arrow-down:before {
          content: "\e790";
        }
      }
    }

    .el-menu--horizontal {
      .el-menu--popup {
        min-width: 100px;
        background-color: #fafafa;
        padding: 10px 0;
        box-shadow: 0px 3px 10px 0px rgba(0, 52, 103, 0.4);

        &.el-menu--popup-bottom-start {
          margin-top: 0;
        }

        .el-menu-item {
          font-family: "SourceHanSansCN-Regular";
          color: $myGrey;
          min-width: 80px;
          padding: 0 20px 0;
          border-radius: 2px;
          background-color: #fafafa;

          &.is-active,
          &:hover {
            background-color: #e3f0ff;
            color: $myBlue !important;
          }
        }
      }
    }
  }
}

.el-menu--horizontal {
  width: 100%;

  .el-menu--popup {
    width: 100%;
    max-width: 1248px;
    margin: -13px auto 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .el-menu-item:not(.is-disabled):focus,
  .el-menu-item:not(.is-disabled):hover {
    color: $myBlue !important;
  }
}

.app-nav {
  .app-menu {
    .el-menu {
      .el-menu-item {
        font-size: 20px !important;
        color: #2d3144;
      }

      .el-submenu .el-submenu__title {
        font-size: 20px !important;
        color: #2d3144;
      }

      .el-menu .el-menu-item-group {
        .el-menu-item-group__title {
          padding-left: 20px !important;
          font-size: 16px;
          color: #2d3144;

          .title-line {
            display: inline-block;
            width: 4px;
            height: 24px;
            background: #249cff;
            vertical-align: middle;
          }
        }

        .el-menu-item {
          font-size: 16px !important;
        }
      }
    }
  }
}
</style>
<style lang='scss' scoped>
$myBlue: #249cff;
$myGrey: #646464;

.nav {
  width: 100%;
  height: 72px;
  line-height: 72px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  &:hover {
    background: #fff;
    border-bottom: 1px solid transparent;
    box-shadow: 0 0 20px 0 rgb(15 41 131 / 10%);
  }

  // logo样式
  .logo-icon {
    width: 82px;
    margin-top: -6px;
    margin-right: 0px;
    vertical-align: middle;
  }

  // 登陆注册
  .login-btn {
    display: inline-block;
    width: 90px;
    height: 38px;
    line-height: 40px;
    border: 1px solid #2196f3;
    font-size: 16px;
    border-radius: 20px;
    font-weight: 400;
    color: #2196f3;

    &:hover {
      color: $myBlue;
    }
  }

  .register-btn {
    display: inline-block;
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-family: "SourceHanSansCN-Regular";
    font-weight: 400;
    color: #ffffff;
    background: #2196f3;
    border-radius: 20px;
    margin-left: 15px;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0px 1px 10px 0px rgba(36, 156, 255, 0.5);
    }
  }

  // web端
  .web-nav {
    max-width: 1248px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-menu {
      width: 736.382px !important;
    }

    .web-el-menu.el-menu.el-menu--horizontal {
      display: flex;
      justify-content: space-around;
      border-bottom: none;
    }
  }

  // 滑动背景白色
  &.nav-color {
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px 0px rgba(100, 100, 100, 0.2);

    &.el-menu {
      display: inline-block;
      vertical-align: top;
      background-color: transparent !important;

      &.el-menu--horizontal {
        border-bottom: none;

        &:focus {
          outline: 0 !important;
        }
      }
    }

    &.el-menu--horizontal > .el-menu-item {
      font-size: 14px !important;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      padding: 0;
      margin-right: 25px;
      color: $myGrey;
      height: 72px !important;
      line-height: 72px !important;
      box-sizing: border-box;

      &:last-of-type {
        margin-right: 0;
      }

      &.is-active {
        color: $myGrey;
        border-bottom: none;
      }

      &:focus,
      &:hover {
        background-color: transparent;
      }

      &:not(.is-disabled):focus,
      &:not(.is-disabled):hover {
        background-color: transparent !important;
        border-bottom: none;
      }
    }

    &.el-menu--horizontal > .el-submenu {
      &.is-active {
        .el-submenu__title {
          color: $myGrey;
          border-bottom: none;
        }
      }

      &:hover {
        .el-submenu__title {
          border-bottom: none;
        }
      }

      .el-submenu__title {
        font-size: 14px !important;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        padding: 0;
        margin-right: 25px;
        color: $myGrey;
        height: 72px !important;
        line-height: 72px !important;
        box-sizing: border-box;

        &:hover {
          background-color: transparent !important;
          border-bottom: none;
        }

        .el-submenu__icon-arrow {
          color: $myGrey;
          margin-left: 4px;

          &.el-icon-arrow-down:before {
            content: "\e790";
          }
        }
      }

      .el-menu--horizontal {
        .el-menu--popup {
          min-width: 100px;
          background-color: #fafafa;
          padding: 10px 0;
          box-shadow: 0px 3px 10px 0px rgba(0, 52, 103, 0.4);

          &.el-menu--popup-bottom-start {
            margin-top: 0;
          }

          .el-menu-item {
            font-family: "PingFangSC-Regular";
            color: $myGrey;
            min-width: 80px;
            padding: 0 20px 0;
            border-radius: 2px;
            background-color: #fafafa;

            &.is-active,
            &:hover {
              background-color: #e3f0ff;
              color: $myBlue;
            }
          }
        }
      }
    }
  }

  // 移动端
  .app-nav {
    .login-btn {
      width: 64px;

      &:hover {
        color: $myBlue;
      }
    }

    .register-btn {
      width: 96px;

      &:hover {
        box-shadow: 0px 1px 10px 0px rgba(36, 156, 255, 0.5);
      }
    }

    .app-menu {
      height: 100vh;
      overflow-y: scroll;
      overscroll-behavior-y: contain;

      .el-menu {
        .el-submenu {
          text-align: left;
          border-bottom: 1px solid #e0e8ee;

          .el-menu {
            .el-menu-item-group {
              .el-menu-item-group__title {
                font-size: 20px;

                color: #2d3144;
              }
            }
          }
        }
      }
    }

    .flex {
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
    }

    .app-nav-menu {
      width: 10%;
      margin-left: 32px;

      .icon-liebiao {
        color: #2c3e50;
        font-size: 24px;
      }
    }

    .app-menu {
      display: none;
    }

    .isCollapse {
      display: block;
    }
  }
}

.el-menu--horizontal {
  .el-menu-pro {
    width: 100%;
    max-width: 1388px;
    display: flex;
  }

  .pro-item {
    width: 33%;
    padding: 26px;
    background: #fff;

    .pro-item-tit {
      font-size: 16px !important;
      font-family: "PingFang SC", "microsoft yahei", tahoma, arial,
        "Hiragino Sans GB", sans-serif !important;
      color: #212121;
    }
    .divider {
      margin: 0;
      width: 40%;
    }

    .pro-item-li {
      position: relative;
      margin: 13px 0;
      font-family: "PingFang SC", "microsoft yahei", tahoma, arial,
        "Hiragino Sans GB", sans-serif !important;
      font-size: 14px !important;
      font-weight: 400;
      color: #2d3144;
      letter-spacing: 0;

      .iconNew {
        position: absolute;
        top: -2px;
        right: 57%;
        width: 24px;
        height: 12px;
      }

      .iconHot {
        position: absolute;
        top: -2px;
        right: 64%;
        width: 24px;
        height: 12px;
      }

      p {
        margin-top: -10px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        color: #808894;
      }

      p:hover {
        cursor: pointer;
      }
    }

    .el-menu-item {
      color: #212121;

      &.is-active,
      &:hover {
        color: $myBlue;
      }
    }
  }
}

.el-menu--horizontal .el-menu .el-menu-pro .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: transparent;
  padding: 0;
}

.el-menu-item .pro-itemss {
  width: 100%;
  height: 100%;
  background-color: #fff !important;
}

.el-menu-item .pro-itemss:hover {
  background-color: #dcebf7 !important;
  color: #212121 !important;
}

@media screen and (max-width: 375px) {
  .nav .app-nav .flex {
    padding: 0;
  }
}

@media screen and (max-width: 1050px) {
  .nav {
    .login-btn {
      width: 60px;
    }

    .register-btn {
      width: 90px;
    }
  }
}
</style>