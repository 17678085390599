import request from '@/utils/request'
export function Point(data) {
    return request({
        url: '/htuc/v1/onClick/Point ',
        headers: {
            isToken: false
        },
        isShow: true,
        method: 'post',
        data: data
    })
}