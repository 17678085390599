import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import track from './utils/track';

Vue.prototype.$track = track;
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import VueElementUISkeleton from 'vue-elementui-skeleton';
import Cookies from 'js-cookie';
import i18n from './lang';

import './style/index.scss'; // global css
import './icons'; // icon
import './assets/font/font.css'; //font
import './assets/iconfont/iconfont.css'; //font
import VueHighlightJS from '../src/mixins/textUpdate.js';
import 'highlight.js/styles/googlecode.css';
import 'swiper/css/swiper.css';
Vue.use(VueHighlightJS);
import VueQuillEditor from 'vue-quill-editor';
//导入富文本编辑器对应的样式
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
//将富文本编辑器，注册为全局可用的组件
Vue.use(VueQuillEditor);
Vue.use(Element, {
	size: Cookies.get('size') || 'medium', // set element-ui default size
	i18n: (key, value) => i18n.t(key, value),
});
Vue.use(VueElementUISkeleton, {
	directiveName: 'my-skeleton',
	rows: 10,
	radius: 3,
	bg: 'red',
});

Vue.directive('title', {
	//单个修改标题
	inserted: function(el, binding) {
		document.title = el.dataset.title;
	},
});
Vue.config.productionTip = false;

import AMap from 'vue-amap'; //高德地图
Vue.use(AMap);
// 初始化vue-amap
AMap.initAMapApiLoader({
	key: '0fb4bf3f7fe6623e1d2f46ab00688b6d', // 高德key
	// 插件集合
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
	v: '1.4.15',
	uiVersion: '1.0', // ui库版本，不配置不加载,
});
const debounce = (fn, delay) => {
	let timer = null;
	return function() {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function() {
			fn.apply(context, args);
		}, delay);
	};
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor (callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};
new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app');
