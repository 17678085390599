import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		// 开发文档地址
		docUrl: 'https://doc.texeljoy.com/',
		// 人像人体集成
		humman_SDK: 'hummanBody/beauty/SDK/example.html',
		// 人像美颜sdk
		humman_beauty: 'hummanBody/beauty/SDK/sdkDownload.html',
		// 人脸sdk
		humman_faceKey: 'hummanBody/faceKey/SDK/sdkDownload.html',
		// 内容审核指南
		audit_guide: 'contentAudit/picture/guide/operation.html',
		// 在线客服指南
		customer_guide: 'Intelligent/customer/guide/operation.html',
		// 图像
		image_guide: 'imageTechnology/specialEffect/guide/operation.html',
		isMobile: false,
	},
	getters: {},
	mutations: {
		changeMobile: (state, isMobile) => {
			state.isMobile = isMobile;
		},
	},
	actions: {},
	modules: {},
});
