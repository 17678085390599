import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import Cookies from 'js-cookie'
import zhCN from './zh'

// 准备翻译的语言环境信息
const messages = {
  // 简体中文
  'zh-CN': {
    ...zhCN
  }
}

// 获取默认语言
export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage && Object.keys(messages).indexOf(chooseLanguage) > -1) return chooseLanguage
  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'zh-CN'
}

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: getLanguage(), // 设置地区
  messages, // 设置地区信息
  silentTranslationWarn: true // 没有的key值不发出警告
})

export default i18n
