<template>
  <div class="footer">
    <div class="footer-centre">
      <el-row class="footer-centre-top">
        <el-col :sm="8" :xs="24">
          <el-row class="logo-item mb20">
            <el-col :sm="24" :xs="12">
              <img
                class="logo"
                src="@/assets/img/虹图logo_white@2x.png"
                alt="虹图+"
              />
              <a
                href="https://texeljoyar.com/"
                target="_blank"
                class="lang-btn hidden-sm-and-up"
              >
                <img src="../assets/img/矢量智能对象@2x(3).png" alt="语言" />
                Intl-English
              </a>
            </el-col>
            <el-col :sm="24" :xs="12">
              <div class="other f-fRegular mt15">
                <div class="other-icon mb10">
                  <span class="other-icon-tit">联系方式</span>
                  <span>400-178-9918</span>
                </div>

                <div class="other-icon">
                  <span class="other-icon-tit">微信公众号</span>
                  <img
                    src="../assets/img/gongzhonghao.png"
                    class="wechat-icon"
                  />
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :sm="16" :xs="24">
          <div class="left-ul flex f-fMedium f-s12 hidden-xs-only">
            <ul class="list" v-for="item in footerList" :key="item.id">
              <li class="list-title">{{ item.title }}</li>
              <li v-for="(child, i) in item.menu" :key="i">
                <a
                  :href="child.path"
                  target="_blank"
                  v-show="checkAddress(child.path)"
                  >{{ child.name }}</a
                >
                <router-link
                  :to="child.path"
                  v-show="!checkAddress(child.path)"
                  >{{ child.name }}</router-link
                >
              </li>
            </ul>
          </div>
          <el-collapse class="left-ul hidden-sm-and-up mt20" accordion>
            <el-collapse-item
              :title="item.title"
              :name="item.id"
              v-for="item in footerList"
              :key="item.id"
            >
              <div v-for="(child, i) in item.menu" :key="i">
                <a
                  :href="child.path"
                  target="_blank"
                  v-show="checkAddress(child.path)"
                  >{{ child.name }}</a
                >
                <router-link
                  :to="child.path"
                  v-show="!checkAddress(child.path)"
                  >{{ child.name }}</router-link
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>

      <div class="right-img flex f-fMedium">
        <div class="email" @click="jump()">
          邮箱订阅
          <input
            class="email-input"
            placeholder="请输入您的邮箱"
            type="email"
            id="email"
            name="email"
          />

          <el-button
            :class="{ 'email-button': isPro }"
            type="primary"
            size="mini"
            @click="checkEmail()"
          >
            订阅
          </el-button>
        </div>

        <a
          href="https://texeljoyar.com/"
          target="_blank"
          class="lang-btn hidden-xs-only"
        >
          <img src="../assets/img/矢量智能对象@2x(3).png" alt="语言" />
          Intl-English
        </a>
      </div>
      <el-divider class="bg-999 hidden-xs-only"></el-divider>
      <div class="record-number f-fRegular f-s12 f-w400">
        <p>
          © 2021-{{ date }} texeljoy.com , All Rights Reserved |
          <a href="https://beian.miit.gov.cn" target="_blank"
            >苏ICP备2022014293号-1</a
          >
        </p>
        <p>
          南京图捷智能科技有限公司
          <span class="serve"
            ><router-link to="/agreement/userAgreement"
              >| 用户协议 |
            </router-link></span
          >
          <span style="margin-left: 4px">
            <router-link to="/agreement/privacy">隐私政策</router-link>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getEmail } from "@/api/index";
import { checkAddress } from '../utils/validate';

export default {
  data() {
    return {
      footerList: [{
        id: 1,
        title: '凤羽引擎',
        menu: [{ name: '人像美颜特效', path: '/human/beautySDK' },
        { name: 'AR贴纸道具', path: '/human/ARSticker' },
        { name: '人像抠图', path: '/human/portrait' },
        { name: '3D Avatar', path: '/human/3DAvatar' },
        { name: '肢体识别', path: '/human/behavior' },
        ]
      },
      {
        id: 2,
        title: '烛照引擎',
        menu: [{ path: '/smart/photoReview', name: '图片审核' },
        { path: '/smart/videoReview', name: '视频审核' },
        { path: '/smart/audioReview', name: '音频审核' },
        { path: '/smart/textReview', name: '文字审核' },
        { name: '人脸实名认证', path: '/smart/authenticat' },
        ]
      },
      {
        id: 3,
        title: '智能应用',
        menu: [{ name: 'AIGC智能在线客服系统', path: '/smart/smartCS' },
        ]
      },
      {
        id: 4,
        title: '图像技术',
        menu: [{ name: '图像特效', path: '/image-technology/effects', },
        { name: '图像增强', path: '/image-technology/enhance' },
        ]
      },

      {
        id: 5,
        title: '服务与支持',
        menu: [
          { name: '文档中心', path: this.$store.state.docUrl },
          { name: '联系我们', path: '/contact' },
          { name: '提交反馈', path: '/contact' },
          { name: '隐私政策', path: '/agreement/privacy' },
          { name: '用户协议', path: '/agreement/userAgreement' },
        ]
      },
      ],
      isPro: false,
      date: new Date().getFullYear(),
    };
  },
  watch: {
    $route(to, from) {
      console.log(to.path, to.path == '/meiyanSDK');
      this.isPro = to.path == '/meiyanSDK';
    }
  },
  created() {
    let res = this.$route.path;
    this.isPro = res == '/meiyanSDK';
  },
  methods: {
    //邮箱接口
    getEmail() {
      let data = {
        mailboxEmail: document.getElementById("email").value,
        mailboxSource: "web端",
      };

      getEmail(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "订阅成功",
            type: "success",
          });
          document.getElementById("email").value = null;
        }
      });
    },
    //邮箱订阅
    checkEmail(path) {
      var email = document.getElementById("email").value;
      var regex =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9_\.\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (regex.test(email)) {
        this.getEmail();
      } else {
        alert("请输入正确的邮箱");
      }
    },
    jump(path) {
      this.$router.push({ path });
    },
    checkAddress
  },
};
</script>

<style lang="scss">
.left-ul {
  &.hidden-sm-and-up {
    border-top: 1px solid #999;

    .el-collapse-item {
      .el-collapse-item__header {
        background: #151c29;
        color: #ffffff;
        border-bottom: 1px solid #999999;
        font-size: 16px;

        .el-icon-arrow-right:before {
          content: "+";
          font-size: 26px;
          font-weight: 500;
        }
      }

      .el-collapse-item__content {
        background: #151c29;
        color: #ffffff;
        font-size: 16px;
        line-height: 35px;
        padding: 15px 0;
      }
    }
  }
}
</style>
<style lang='scss' scoped>
$myBlue: #249cff;

.flex {
  width: 100%;
  justify-content: space-between;
}

.footer {
  width: calc(100% - 30px);
  padding: 0 15px;
  background: #151c29;

  .email input {
    color: white;
  }

  .footer-centre {
    max-width: 1245px;
    margin: auto;
    color: #999999;
    font-weight: 400;

    .footer-centre-top {
      padding-top: 48px;
    }
  }

  .logo-item {
    text-align: left;
    color: #fff;

    .logo {
      height: 61px;
    }

    .lang-btn {
      display: block;
      margin-top: 27px;
    }

    .other {
      font-size: 14px;

      .other-icon {
        .other-icon-tit {
          display: inline-block;
          width: 93px;
        }

        .wechat-icon {
          width: 90px;
          height: 90px;
          vertical-align: text-top;
        }
      }
    }
  }

  .left-ul {
    // width: 68.5%;
    text-align: left;

    .list {
      a {
        color: #999999;

        &:hover {
          color: $myBlue;
        }
      }

      line-height: 28px;

      .list-title {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 12px;
      }
    }
  }

  .right-img {
    margin-top: 70px;
    font-size: 14px;
    align-items: center;

    .email {
      .email-input {
        width: 142px;
        height: 16px;
        background: rgba(153, 153, 153, 0.3);
        border: 1px solid rgba($color: #999, $alpha: 0.5);
        padding: 5px;
        caret-color: #fff;
        margin: 0 8px 0 15px;
      }
      .email-button {
        color: #fff;
        background-color: #7869ff;
        border: #7869ff;
      }
    }
  }

  .lang-btn {
    display: inline-block;
    width: 160px;
    height: 40px;
    line-height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    border-radius: 20px;
    text-align: center;

    img {
      width: 24px;
      margin-right: 13px;
      vertical-align: middle;
    }
  }

  .record-number {
    line-height: 26px;
    padding-bottom: 30px;
  }

  .serve:hover {
    color: #249cff;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    .other {
      .other-icon {
        font-size: 12px;
      }
    }
  }

  .right-img {
    justify-content: center;
    margin-bottom: 30px;
  }
}
</style>