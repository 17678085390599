/*
 * @Author: jimengyu jimengyu@toivan.com
 * @Date: 2022-11-26 16:12:36
 * @LastEditors: jimengyu jimengyu@toivan.com
 * @LastEditTime: 2022-12-19 13:41:39
 * @FilePath: /ht_web/src/utils/track.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import { Point } from "@/api/maidian";
// 埋点指令
let track = Vue.directive("track", {
    bind: (el, binding, vnode) => {

        if (binding.value && binding.value.act) {
            switch (binding.value.act) {
                case "browse":
                case "click":
                    el.onclick = function() {
                        // 在此插入埋点数据(调用API)
                        // console.log(binding.value);
                        const { act, node } = binding.value;
                        Point(node).then((res) => {
                        });
                    };
                    break;
            }
        }
    },
});
export default track;