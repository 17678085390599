/** 链接
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 地址是否以http/https开头
 * @param {string}
 * @returns {Boolean}
 */
export function checkAddress(url) {
  let res = url?.startsWith('http') || url?.startsWith('https')
  return res
}