import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      name: 'msvalidate.01',
      content: {
        content: '2FED58C6BF89816D8F1D9E54BD4694CE'
      }
    },
    component: () => import("../views/home/index"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: { title: "contact" },
    component: () => import("../views/contact/index"),
  },
  // 百度推广
  {
    path: "/meiyanSDK",
    name: "MeiyanSDK",
    meta: {
      name: 'msvalidate.01',
      content: {
        content: '2FED58C6BF89816D8F1D9E54BD4694CE',
        keywords: '美颜SDK，虹图，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK/萌颜SDK「虹图AI开放平台 - 美颜SDK/萌颜SDK」提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      },
      title: '虹图美颜SDK | 虹图AI开放平台,虹图美颜SDK专注实时直播美颜SDK-萌颜SDK',
    },
    component: () => import("../views/promotion/index"),
  },
  // 下载页
  {
    path: "/download",
    name: "Download",
    meta: { title: "下载页" },
    component: () => import("../views/download/index"),
  },
  // 试用申请
  {
    path: "/apply",
    name: "Apply",
    meta: { title: "试用申请" },
    component: () => import("../views/apply/index"),
  },
  // 快速调用
  {
    path: "/wechat-quickCall",
    name: "WechatQuickCall",
    meta: { title: "快速调用" },
    component: () => import("../views/wechat/index"),
  },
  //社区
  {
    meta: { title: "社区_虹图AI开放平台_为企业迅速AI赋能的人工智能开放平台" },
    path: "/community",
    name: "community",
    component: () => import("../views/community/index"),
  },
  {
    meta: { title: "社区_虹图AI开放平台_为企业迅速AI赋能的人工智能开放平台" },
    path: "/communitySearch",
    name: "communitySearch",
    component: () => import("../views/community/communitySearch/index"),
  },
  {
    meta: { title: "社区_虹图AI开放平台_为企业迅速AI赋能的人工智能开放平台" },
    path: "/communitydetails",
    name: "communitydetails",
    component: () => import("../views/community/communitydetails/index"),
  },
  {
    meta: { title: "社区_虹图AI开放平台_为企业迅速AI赋能的人工智能开放平台" },
    path: "/communityCreate",
    name: "communityCreate",
    component: () => import("../views/community/communityCreate/index"),
  },
  {
    meta: { title: "社区_虹图AI开放平台_为企业迅速AI赋能的人工智能开放平台" },
    path: "/communityEditor",
    name: "communityEditor",
    component: () => import("../views/community/communityEditor/index"),
  },
  {
    meta: { title: "社区_虹图AI开放平台_为企业迅速AI赋能的人工智能开放平台" },
    path: "/userCenter",
    name: "userCenter",
    component: () => import("../views/community/userCenter/index"),
  },
  {
    meta: { title: "社区_虹图AI开放平台_为企业迅速AI赋能的人工智能开放平台" },
    path: "/notification",
    name: "notification",
    component: () => import("../views/community/notification/index"),
  },
  // 人像人体
  {
    path: "/human/beautySDK",
    name: "BeautySDK",
    meta: {
      title: "人像美颜",
      content: {
        keywords: '虹图，美颜SDK，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      }
    },
    component: () => import("../views/human-body/beautySDK/index"),
  },
  // {
  //   path: "/human/activity",
  //   name: "Activity",
  //   meta: {
  //     title: "抽奖活动"
  //   },
  //   component: () => import("../views/human-body/activity/index"),
  // },
  {
    path: "/human/ARMakeup",
    name: "ARMakeup",
    meta: {
      title: "AR美妆",
      content: {
        keywords: '虹图，美颜SDK，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      }
    },
    component: () => import("../views/human-body/ARMakeup/index"),
  },
  {
    path: "/human/ARSticker",
    name: "ARSticker",
    meta: {
      title: "AR贴纸道具",
      content: {
        keywords: '虹图，美颜SDK，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      }
    },
    component: () => import("../views/human-body/ARSticker/index"),
  },
  {
    path: "/human/portrait",
    name: "Portrait",
    meta: {
      content: {
        keywords: '虹图，美颜SDK，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      }
    },
    component: () => import("../views/human-body/portrait/index"),
  },
  {
    path: "/human/gesture",
    name: "Gesture",
    meta: {
      content: {
        keywords: '虹图，美颜SDK，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      }
    },
    component: () => import("../views/human-body/gesture/index"),
  },
  {
    path: "/human/behavior",
    name: "Behavior",
    meta: {
      content: {
        keywords: '虹图，美颜SDK，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      }
    },
    component: () => import("../views/human-body/behavior/index"),
  },
  {
    path: "/human/face",
    name: "face",
    meta: {
      content: {
        keywords: '虹图，美颜SDK，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      }
    },
    component: () => import("../views/human-body/face/index"),
  },
  {
    path: "/human/3DAvatar",
    name: "3DAvatar",
    meta: {
      content: {
        keywords: '虹图，美颜SDK，萌颜SDK，直播第三方美颜SDK，美颜特效SDK，直播美颜SDK，免费美颜SDK，美颜美型SDK，美颜接口，第三方美颜接口，虹图美颜SDK，虹图人像人体，人像人体SDK，人脸特效，大眼，瘦脸，额头，下巴，瘦鼻，嘴型，美颜，滤镜，美型，贴纸，人像美化，一键美颜，手势识别，人像抠图，水印，虹图AI开放平台，相芯美颜SDK，火山美颜SDK，字节跳动美颜SDK，相芯',
        description: '虹图美颜SDK提供专业级的行业解决方案，为客户提供美颜美型、动态贴纸、特效滤镜、手势识别、一键美颜、人像抠图、水印等一站式解决方案，广泛适用于直播、音视频等行业，虹图人像美颜SDK，三行代码，快速接入美肤、美型、美颜滤镜、道具贴纸等功能，助力行业发展'
      }
    },
    component: () => import("../views/human-body/avatar"),
  },

  // 社交娱乐
  {
    path: "/social/timelyIM",
    name: "TimelyIM",
    component: () => import("../views/social/timely-im/index"),
  },
  // 智能应用
  {
    path: "/smart/smartCS",
    name: "SmartCS",
    component: () => import("../views/smart-app/smart-cs/index"),
  },
  {
    path: "/smart/authenticat",
    name: "Authenticat",
    component: () => import("../views/smart-app/authenticat/index"),
  },
  {
    path: "/smart/contentReview",
    name: "ContentReview",
    meta: {
      content: {
        keywords: '虹图，虹图AI开放平台，内容审核，内容安全，智能审核，违规图片识别，违规内容自动检测，违规图像识别，视频检测违规，视频内容检测，敏感内容检测，敏感内容，人工智能鉴黄，图普内容审核，数美内容审核，数美，图普',
        description: '虹图内容审核基于AI深度学习的一站式内容审核方案，精准识别图片/直播/音视频中内容违规行为，有效过滤涉黄_违禁等视频图片内容，虹图内容审核帮助企业全面规避业务风险'
      }
    },

    component: () => import("../views/smart-app/contentReview/index"),
  },
  {
    path: "/smart/photoReview",
    name: "photoReview",
    meta: {

      content: {
        keywords: '虹图，虹图AI开放平台，内容审核，内容安全，智能审核，违规图片识别，违规内容自动检测，违规图像识别，视频检测违规，视频内容检测，敏感内容检测，敏感内容，人工智能鉴黄，图普内容审核，数美内容审核，数美，图普',
        description: '虹图内容审核基于AI深度学习的一站式内容审核方案，精准识别图片/直播/音视频中内容违规行为，有效过滤涉黄_违禁等视频图片内容，虹图内容审核帮助企业全面规避业务风险'
      }
    },
    component: () => import("../views/smart-app/photoReview/index"),
  },
  {
    path: "/smart/videoReview",
    name: "videoReview",
    meta: {

      content: {
        keywords: '虹图，虹图AI开放平台，内容审核，内容安全，智能审核，违规图片识别，违规内容自动检测，违规图像识别，视频检测违规，视频内容检测，敏感内容检测，敏感内容，人工智能鉴黄，图普内容审核，数美内容审核，数美，图普',
        description: '虹图内容审核基于AI深度学习的一站式内容审核方案，精准识别图片/直播/音视频中内容违规行为，有效过滤涉黄_违禁等视频图片内容，虹图内容审核帮助企业全面规避业务风险'
      }
    },
    component: () => import("../views/smart-app/videoReview/index"),
  },
  {
    path: "/smart/audioReview",
    name: "audioReview",
    meta: {

      content: {
        keywords: '虹图，虹图AI开放平台，内容审核，内容安全，智能审核，违规图片识别，违规内容自动检测，违规图像识别，视频检测违规，视频内容检测，敏感内容检测，敏感内容，人工智能鉴黄，图普内容审核，数美内容审核，数美，图普',
        description: '虹图内容审核基于AI深度学习的一站式内容审核方案，精准识别图片/直播/音视频中内容违规行为，有效过滤涉黄_违禁等视频图片内容，虹图内容审核帮助企业全面规避业务风险'
      }
    },
    component: () => import("../views/smart-app/audioReview/index"),
  },
  {
    path: "/smart/textReview",
    name: "textReview",
    meta: {

      content: {
        keywords: '虹图，虹图AI开放平台，内容审核，内容安全，智能审核，违规图片识别，违规内容自动检测，违规图像识别，视频检测违规，视频内容检测，敏感内容检测，敏感内容，人工智能鉴黄，图普内容审核，数美内容审核，数美，图普',
        description: '虹图内容审核基于AI深度学习的一站式内容审核方案，精准识别图片/直播/音视频中内容违规行为，有效过滤涉黄_违禁等视频图片内容，虹图内容审核帮助企业全面规避业务风险'
      }
    },
    component: () => import("../views/smart-app/textReview/index"),
  },
  {
    path: "/smart/onlineService",
    name: "onlineService",
    component: () => import("../views/smart-app/onlineService/index"),
  },
  {
    path: "/smart/robotService",
    name: "robotService",
    component: () => import("../views/smart-app/robotService/index"),
  },
  {
    path: "/smart/userService",
    name: "userService",
    component: () => import("../views/smart-app/userService/index"),
  },
  //图像技术
  {
    path: "/image-technology/effects",
    name: "effects",
    meta: {
      content: {
        keywords: '图像风格转换,虹图图像风格转换，百度图像风格转换,百度AI图像风格转换,图像风格转化,图像风格转换系统,图风格转换算法,风格滤镜',
        description: '虹图图像风格转换,将图像转换成卡通画,铅笔画,哥特油画等9种艺术风格,可用于开展趣味活动,或集成到美图应用中对图像进行风格转换.'
      }
    },
    component: () => import("../views/image-technology/effects/index"),
  },
  {
    path: "/image-technology/photo",
    name: "photo",
    meta: {
      content: {
        keywords: '图像风格转换,虹图图像风格转换，百度图像风格转换,百度AI图像风格转换,图像风格转化,图像风格转换系统,图风格转换算法,风格滤镜',
        description: '虹图图像风格转换,将图像转换成卡通画,铅笔画,哥特油画等9种艺术风格,可用于开展趣味活动,或集成到美图应用中对图像进行风格转换.'
      }
    },
    component: () => import("../views/image-technology/photo/index"),
  },
  {
    path: "/image-technology/animification",
    name: "animification",
    meta: {
      content: {
        keywords: '人像动漫化,二次元动漫化,二次元动漫人像,特效相机,图片趣味处理,个性头像,动漫头像',
        description: '虹图人像动漫化,结合人脸检测、头发分割、人像分割等技术,为用户量身定制千人千面的二次元动漫形象,并支持通过参数设置,生成二次元动漫人像.'
      }
    },
    component: () => import("../views/image-technology/animification/index"),
  },
  {
    path: "/image-technology/enhance",
    name: "enhance",
    meta: {

      content: {
        keywords: '虹图图像对比度增强,图像对比度增强算法,图像识别,图像处理,图像优化,图像美化,对比度',
        description: '虹图图像对比度增强，百度AI图像对比度增强,调整过暗或者过亮图像的对比度,使图像更加鲜明.'
      }
    },
    component: () => import("../views/image-technology/enhance/index"),
  },
  {
    path: "/image-technology/enhance/contrast",
    name: "contrast",
    meta: {

      content: {
        keywords: '虹图图像对比度增强,图像对比度增强算法,图像识别,图像处理,图像优化,图像美化,对比度',
        description: '虹图图像对比度增强，百度AI图像对比度增强,调整过暗或者过亮图像的对比度,使图像更加鲜明.'
      }
    },
    component: () => import("../views/image-technology/enhance/contrast"),
  },
  {
    path: "/image-technology/enhance/color",
    name: "color",
    meta: {
      content: {
        keywords: '特效相机,图片色彩处理,图像色彩增强,图像色彩增强算法,百度图像色彩增强,百度AI图像色彩增强,图像增强,色彩增强',
        description: '百度AI图像色彩增强,可智能调节图片的色彩饱和度,亮度,对比度,使得图片内容细节,色彩更加逼真'
      }
    },
    component: () => import("../views/image-technology/enhance/color"),
  },
  // 行业解决
  {
    path: "/industry/live",
    name: "Live",
    component: () => import("../views/Industry-solve/live/index"),
  },
  {
    path: "/industry/audioVideo",
    name: "AudioVideo",
    component: () => import("../views/Industry-solve/audio-video/index"),
  },
  {
    path: "/industry/wisdom",
    name: "Wisdom",
    component: () => import("../views/Industry-solve/wisdom/index"),
  },
  {
    path: "/industry/human",
    name: "Human",
    component: () => import("../views/Industry-solve/human/index"),
  },
  {
    path: "/industry/liveTerminal",
    name: "LiveTerminal",
    component: () => import("../views/Industry-solve/live-terminal/index"),
  },
  // 场景解决
  {
    path: "/scene/agility",
    name: "Agility",
    component: () => import("../views/scene-solve/agility/index"),
  },
  {
    path: "/scene/norm",
    name: "Norm",
    component: () => import("../views/scene-solve/norm/index"),
  },
  {
    path: "/scene/campus",
    name: "Campus",
    component: () => import("../views/scene-solve/campus/index"),
  },
  {
    path: "/scene/metagalaxies",
    name: "metagalaxies",
    component: () => import("../views/scene-solve/metagalaxies/index"),
  },
  {
    path: "/scene/photoBooth",
    name: "photoBooth",
    component: () => import("../views/scene-solve/photoBooth/index"),
  },
  {
    path: "/scene/socialize",
    name: "socialize",
    component: () => import("../views/scene-solve/socialize/index"),
  },
  {
    path: "/scene/educate",
    name: "socialize",
    component: () => import("../views/scene-solve/educate/index"),
  },
  {
    path: "/scene/content",
    name: "content",
    component: () => import("../views/scene-solve/content/index"),
  },
  {
    path: "/scene/smartCS",
    name: "smartCS",
    component: () => import("../views/scene-solve/smartCS/index"),
  },
  //协议
  {
    path: "/agreement/privacy",
    name: "Privacy",
    component: () => import("../views/agreement/privacy"),
  },
  {
    path: "/agreement/userAgreement",
    name: "userAgreement",
    component: () => import("../views/agreement/userAgreement"),
  },
  //404页面
  {
    path: "/notFound", //匹配未定义的路由
    name: "notFound",
    component: () => import("../views/notFound/index"),
  },
  {
    path: "*",
    redirect: "/notFound",
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return { y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.content && to.meta.content.keywords) {
    const head = document.getElementsByTagName('head');
    const meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords);
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  if (to.meta.name) {
    // 使用 window.onload 监听所有内容加载完毕
    let metaElement = document.createElement('meta');
    metaElement.setAttribute('name', 'msvalidate.01');
    metaElement.setAttribute('content', '2FED58C6BF89816D8F1D9E54BD4694CE');
    setTimeout(() => {
      const lastElement = document.head.lastElementChild;
      lastElement.insertAdjacentElement('afterend', metaElement);
    }, 5000);
  }
  if (to.matched.length == 0) {
    from.path ? next({ name: from.name }) : next("/notFound");
  } else {
    // 参数是固定的3个:
    // to: 要跳转到的位置
    // from: 当前位置
    // next: 放行, 允许跳转
    // document.title = to.meta.title ? to.meta.title : "hongtu";
    next(); //通过next()还可以解决相同路径不允许重复跳转的问题
  }
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
export default router;
