<template>
  <div id="app" ref="app">
    <el-skeleton animated :loading="loading" />
    <Header v-if="header_show"></Header>
    <router-view v-on:header="header" v-on:footer="footer" />
    <Footer v-if="footer_show"></Footer>
    <!-- 离线咨询 -->
    <el-popover class="popover" placement="left" width="260" trigger="hover">
      <div class="offline-options bg-w box-c">
        <div class="flex mb10 options cursor-p box-c">
          <i class="iconfont icon-dianhuazixun mr10" />
          <div>
            <div class="options-tit R16-400 mb5">电话咨询</div>
            <span class="options-desc M20-500">400-178-9918</span>
          </div>
        </div>
        <div class="flex mb10 options cursor-p box-c" @click="linkTo">
          <i class="iconfont icon-gongdan mr10" />
          <div>
            <div class="options-tit R16-400 mb5">提交工单</div>
            <span class="options-desc R14-400"
              >咨询功能、报价、集成方式等问题</span
            >
          </div>
        </div>
        <div class="flex mb10 options cursor-p box-c">
          <i class="iconfont icon-31erweima mr10" />
          <div class="mr20">
            <div class="options-tit R16-400 mb5">微信咨询</div>
            <span class="options-desc R14-400">扫码直接联系我们</span>
          </div>
          <img class="options-img" src="./assets/img3/微信咨询.png" alt="" />
        </div>
      </div>
      <div id="offline" class="bg-w box-c cursor-p" slot="reference">
        <span class="iconfont icon-dianhuazixun offline-txt"></span>
        <p class="offline-txt">离线咨询</p>
      </div>
    </el-popover>

    <div class="top_box">
      <div
        id="top"
        class="bg-w box-style cursor-p"
        @click="top()"
        v-show="show"
      >
        <i class="iconfont icon-zhiding"></i>
      </div>
    </div>
    <!-- </el-skeleton> -->
  </div>
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import { useResizeObserver } from '@vueuse/core';

export default {
  components: { Header, Footer },
  data() {
    return {
      header_show: true,
      footer_show: true,
      winHeight: null,
      show: false,
      loading: false,
    };
  },
  mounted() {
    useResizeObserver(this.$refs.app, entries => {
      const entry = entries[0];
      const { width } = entry.contentRect;
      const isMobile = width < 992 ? true : false;
      this.$store.commit('changeMobile', isMobile);
    });
    window.addEventListener('scroll', this.handleScroll, true);
    this.loading = false;
    setTimeout(function() {
      this.loading = false;
    }, 5000);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    header(bool) {
      this.header_show = bool;
    },

    //是否显示底部
    footer(bool) {
      this.footer_show = bool;
    },

    handleScroll(e) {
      if (e.target.scrollTop || document.documentElement.scrollTop) {
        let winHeight = e.target.scrollTop || document.documentElement.scrollTop;
        this.winHeight = winHeight;
        if (this.winHeight >= 900) {
          this.show = true;
        } else {
          this.show = false;
        }
      }

    },
    top() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    },
    linkTo() {
      window.open('https://console.texeljoy.com/');
    },
  },
};
</script>

<style lang="scss">
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.box-style {
  box-shadow: 0px 0px 9px 1px rgba(221, 221, 221, 0.5);
  border-radius: 4px;
}

#offline {
  position: fixed;
  top: calc(50% + 94px);
  // bottom: 66px;
  right: 10px;
  width: 36px;
  height: 74px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 7px 9px 1px rgba(221, 221, 221, 0.5);
  padding: 10px 14px;
  font-size: 13px;
  z-index: 9999;

  &:hover {
    .offline-txt {
      color: #249cff;
    }
  }

  .icon-dianhuazixun {
    font-size: 30px;
    color: #212121;
  }

  .offline-txt {
    color: #212121;
  }
}

.offline-options {
  width: 100%;
  height: 212px;
  padding: 12px 0;
  text-align: left;
  font-size: 16px;

  .options {
    padding: 11px 6px;

    &:hover:nth-child(2) {
      background: rgba(0, 85, 255, 0.04);
      border-radius: 8px;

      .options-tit,
      .iconfont {
        color: #249cff;
      }
    }

    .iconfont {
      font-size: 20px;
    }

    .options-tit {
      color: #212121;
      line-height: 16px;
    }

    .options-desc {
      color: #999999;

      &.M20-500 {
        color: #249cff;
      }
    }

    .options-img {
      width: 60px;
      height: 60px;
    }
  }
}

#top {
  position: fixed;
  z-index: 900;
  right: 10px;
  top: calc(50% + 198px);
  // bottom: 14px;
  width: 64px;
  height: 40px;
  line-height: 40px;

  .icon-zhiding {
    font-size: 40px;
    color: #212121;
  }

  &:hover {
    .icon-zhiding {
      color: #249cff;
    }
  }
}

.popovermsg {
  min-width: auto !important;
}
</style>