/*
 * @Author: jimengyu jimengyu@toivan.com
 * @Date: 2022-10-23 17:17:04
 * @LastEditors: jimengyu jimengyu@toivan.com
 * @LastEditTime: 2023-01-05 11:59:56
 * @FilePath: /myCode/ht_web/src/api/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request';

// 轮播图 -- 获取轮播图列表
export function getUseableList(data) {
  return request({
    url: '/operation/carousel/getUseableList',
    method: 'get',
    params: data,
    requestBaseUrl: 'asvc'
  });
}

// 获取云存储配置
export function getCosProperties(data) {
  return request({
    url: '/htuc/v1/system/cos/getCosProperties',
    method: 'get',
  });
}
//邮箱地址
export function getEmail(data) {
  return request({
    url: '/system/email',
    method: 'post',
    data: data,
    requestBaseUrl: 'asvc'
  });
}
//发送短信验证
export function captchaMessage(data) {
  return request({
    url: `/htuc/v1/entauth/apply/captchaMessage?phone=${data.phone}`,
    method: 'get',
    data: data
  });
}
//发送试用申请
export function sendApply(data) {
  return request({
    url: '/htuc/v1/entauth/sendApply',
    method: 'post',
    data: data
  });
}