import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
import store from "@/store";
import errorCode from "@/utils/errorCode";
import cache from "@/plugins/cache";
import { showFullScreenLoading, tryHideFullScreenLoading, tansParams } from "@/utils/index";
import { changeUrl } from "@/utils/index";


// 是否显示重新登录
export const isRelogin = { show: false };
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
const service = axios.create({
  // axios中请求配置有baseURL选项,表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  // headers: { Authorization: "Bearer " + token },
  timeout: 10000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    const isshow = config.isshow ? config.isshow : false;
    if (!isshow) {
      // showFullScreenLoading();
    }
    // 切换请求地址
    if (config.requestBaseUrl && config.requestBaseUrl === 'asvc') {
      config.baseURL = process.env.VUE_APP_ASVC_API;
    }
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : false;
    const TOKEN = user ? user.token : false;
    const headersToken = config.token ? config.token : true;
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;

    if (TOKEN && !isToken && headersToken) {

      config.headers.Authorization = "Bearer " + TOKEN;
    }
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;

    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (!isRepeatSubmit && (config.method === "post" || config.method === "put")) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === "object" ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime(),
      };
      const sessionObj = cache.session.getJSON("sessionObj");
      if (sessionObj === undefined || sessionObj === null || sessionObj === "") {
        cache.session.setJSON("sessionObj", requestObj);
      } else {
        const s_url = sessionObj.url; // 请求地址
        const s_data = sessionObj.data; // 请求数据
        const s_time = sessionObj.time; // 请求时间
        const interval = 1000; // 间隔时间(ms),小于此时间视为重复提交
        if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
          const message = "数据正在处理,请勿重复提交";
          console.warn(`[${s_url}]: ` + message);
          return Promise.reject(new Error(message));
        } else {
          cache.session.setJSON("sessionObj", requestObj);
        }
      }
    }
    return config;
  },
  (error) => {
    tryHideFullScreenLoading();
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    tryHideFullScreenLoading();
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (res.request.responseType === "blob" || res.request.responseType === "arraybuffer") {
      return res.data;
    }
    if (code === 500) {
      Message({
        message: msg,
        type: "error",
      });
      return res.data;
    } else if (code == 401 || code == 403) {
      // 接口调取失败，清除用户信息
      localStorage.removeItem("user");
      Message({
        message: '用户登录过期，请重新登录',
        type: "error",
        duration: 5 * 1000,
      });
    } else if (code !== 200) {
      Notification.error({
        title: msg,
      });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    tryHideFullScreenLoading();
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;